 .home-layout {
    background-image: url('../../public/Background_Image.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
}

.need-to-register-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;

    background-color: black;

    text-align: center;

    a, p {
        padding-top: 2px;
        padding-bottom: 2px;
        color: white;
        font-size: 1.3rem;
    }
}

@media screen and (max-width:500px) {
    .need-to-register-container {
        a, p {
            font-size: 1rem;
        }
    }
}

