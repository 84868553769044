.tickerShell {
    width: fit-content;
    height: fit-content;
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* align-self: stretch; */
    align-items: center;
    background-color: #fff;
    opacity: 0.70;
    border: 1px solid #000;
    padding: 2rem 2rem;
    border-radius: 5px;
    
    /* h2 {
        width: 100%;
        text-align: center;
        font-size: 2.5rem;
    } */
}


.timeIsUp {
    flex: 1;
    text-align: center;
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 600;
}

.tickerCell {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tickerCellValue {
    font-size: 7rem;
}
    
.tickerCellLabel {
    font-style: italic;
    font-size: 0.85rem;
}

.ticker-separator {
    margin-bottom: 2.5rem; 
    font-size: 7.5rem;
    padding: 0 0.5rem;
}

@media screen and (max-width: 1024px) {
    .tickerShell {
        padding: 1rem 1rem;
    }

    .tickerCellValue {
        font-size: 5rem;
    }

    .tickerCellLabel {
        font-size: 0.75rem;
    }

    .ticker-separator {
        padding-top: 15px;
        font-size: 5rem;
    }
    
}

@media screen and (max-width: 500px) {
    .tickerShell {
        padding: 0.5rem 0.5rem;
    }

    .tickerCellValue {
        font-size: 3.5rem;
    }

    .tickerCellLabel {
        font-size: 0.65rem;
    }

    .ticker-separator {
        padding-top: 20px;
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 375px) {
    .tickerShell {
        padding: 0.5rem 0.5rem;
    }

    .tickerCellValue {
        font-size: 2.5rem;
    }

    .tickerCellLabel {
        font-size: 0.6rem;
    }

    .ticker-separator {
        padding-top: 30px;
        font-size: 2.5rem;
    }
}