.navbar-container {
    width: 100%;
    height: 90px;
}

.transparent {
    background-color: transparent;
}

.black {
    background-color: #1b2430;
}

nav {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 20px 80px;
}

#logo {
    font-size: 2rem;
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar li {
    list-style: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    position: relative;
}

#navbar li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 700;
}

.primary-button {
    background-color: #ffffff;
    color: #000000;

    height: 50px;
    width: 150px;
    border: none;
    border-radius: 3px;
    font-size: 1.3rem;
    cursor: pointer;
    font-weight: 700;
}

.primary-button:hover {
    transform: scale(0.98);
    background-color: #ebebeb;
}

#mobile {
    display: none;
}

#mobile i {
    color: #fff;
    font-size: 30px;
    align-items: center;
    padding-top: 10px;
}

@media screen and (max-width:1024px){
    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 90px;
        right: -300px;
        width: 300px;
        height: 100vh;
        background-color: #2a3239;
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;

        z-index: 999;
    }

    #navbar.active {
        right: 0;
    }

    #navbar li {
        margin-bottom: 25px;
    }

    #mobile {
        display: block;
        cursor: pointer;
    }
}

@media screen and (max-width: 500px) {
    #logo {
        font-size: 1.7rem;
    }

    nav {
        padding: 20px 40px;
    }

    #mobile i {
        font-size: 25px;
    }
    
}

@media screen and (max-width: 375px) {
    #logo {
        font-size: 1.5rem;
    }

    nav {
        padding: 20px 20px;
    }

    #mobile i {
        font-size: 20px;
    }
    
}